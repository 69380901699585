import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../../core/services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  constructor(private translate: TranslateService, private authService: AuthService, private notifyService : NotificationService, private router: Router, private route: ActivatedRoute)
  {

  }

  @Output() sendLoginForm = new EventEmitter<void>();

  public form: FormGroup;
  public flatlogicEmail = '';  // chandan.dwivedi@schlau-digital.de
  public flatlogicPassword = '';  // chandan@123


  public ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl(this.flatlogicEmail, [Validators.required, Validators.email]),
      password: new FormControl(this.flatlogicPassword, [Validators.required])
    });
  }

  public login(): void {
    if (this.form.valid) {

    const params={
        "user": {
            "email": this.form.value.email,
            "password": this.form.value.password
        }
      }

  this.authService.checkLogIn(params)
  .subscribe(
    data => {
      if(data.success==true) {
        window.localStorage.setItem('appcmsUser', JSON.stringify(data.user));
        window.localStorage.setItem('tenantId', data.user.tenantId);
        window.localStorage.setItem('tenant', JSON.stringify(data.user.tenant));

        this.notifyService.showSuccess(this.translate.instant('pages.common.inputValidation.loginSuccess'), this.translate.instant('pages.common.statusEnum.success'));
        this.sendLoginForm.emit(); // theme inbuild token auth generate
       // this.router.navigate(['/orders']);
      } else {
        this.notifyService.showError(this.translate.instant('pages.common.inputValidation.invalidLogin'), this.translate.instant('pages.common.statusEnum.error'));
      }
    },
    err => {
      console.log(err)
      // this.errors = err;
    });

    }
  }


}
