import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import { NgModule } from '@angular/core';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import {AuthGuard} from './pages/auth/guards';
import { CanDeactivateGuard } from './pages/auth/guards/can-deactivate-guard.service';
// import { OrderUpdateLogsComponent } from './pages/order-update-logs/order-update-logs.component';

const routes: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.dashboardModule),
    data: { breadcrumb: 'Dashboard'}
  },
  {
    path: 'orders',
    // pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
    data: { breadcrumb: 'Orders'}
  },
  {
    path: 'categories',
    // pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule),
    data: { breadcrumb: 'Categories'}
  },
  {
    path: 'products',
  //  pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule),
    data: { breadcrumb: 'Products'}
  },
  {
    path: 'suppliers',
    // pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/suppliers/suppliers.module').then(m => m.SuppliersModule),
    data: { breadcrumb: 'Suppliers'}
  },
  {
    path: 'branches',
    // pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/branches/branches.module').then(m => m.BranchesModule),
    data: { breadcrumb: 'Branches'}
  },
  {
    path: 'branches/:id',
    // pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/branches/branches.module').then(m => m.BranchesModule),
    data: { breadcrumb: 'Branches'}
  },
  {
    path: 'members',
  //  pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/members/members.module').then(m => m.MembersModule),
    data: { breadcrumb: 'Members'}
  },

  {
    path: 'orderUpdate',
    loadChildren: () => import('./pages/order-update-logs/order-update-logs.module').then(m => m.OrdersUpdateLogsModule),
  },
  
  {
    path: 'settings',
  //  pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule),
    data: { breadcrumb: 'Settings'}
  },
  {
    path: 'profile',
  //  pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    data: { breadcrumb: 'profile'}
  },
  {
    path: 'staff',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/staff/staff.module').then(m => m.StaffModule),
    data: { breadcrumb: 'Staff'}
  },
  {
    path: 'evaluations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/evaluations/statement.module').then(m => m.statementModule),
    data: { breadcrumb: 'Evaluations'}
  },
  {
    path: 'tenant',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/tenants/tenants.module').then(m => m.TenantsModule),
    data: { breadcrumb: 'Tenant'}
  },
  {
    path: 'subscription',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/subscription/subscription.module').then(m => m.SubscriptionModule),
    data: { breadcrumb: 'Subscription'}
  },
  {
    path: 'supplier-orders',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/supplier-orders/supplier-orders.module').then(m => m.SupplierOrdersModule),
    data: { breadcrumb: 'Subscription'}
  },
  {
    path: 'coupons',
  //  pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/coupons/coupons.module').then(m => m.couponsModule),
    data: { breadcrumb: 'Coupons'}
  }, 
  {
    path: 'qrcode',
  //  pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/qr-code/qrcode.module').then(m => m.qrcodeModule),
    data: { breadcrumb: 'Qrcode'}
  },
  {
    path: 'qrcode/:id',
  //  pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/qr-code/qrcode.module').then(m => m.qrcodeModule),
    data: { breadcrumb: 'Qrcode'}
  },
 
  {
    path: 'notification',
    //pathMatch: 'full',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.notificationModule)
  },
  {
    path: '404',
    component: NotFoundComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    })
  ],
  providers: [ 
    CanDeactivateGuard
],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
