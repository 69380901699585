import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApiService, JwtService } from '../../../core/services';

import { User } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor (
    private apiService: ApiService,
    private jwtService: JwtService,
  ) { }

  checkLogIn(params){    
    return this.apiService.post("user/login", params)
      .pipe(map((data) => {     
        this.jwtService.saveToken(data.token);
        return data; 
      },
      err => { 
        return err;
      }
    ));  
  }

  public login(): void {
    localStorage.setItem('token', 'token');
  }

  public sign(): void {
    localStorage.setItem('token', 'token');
  }

  public signOut(): void {
    localStorage.removeItem('token');
    localStorage.removeItem('appcmsUser');
  }

  public getUser(): Observable<User> {
    return of({
      name: 'John',
      lastName: 'Smith'
    });
  }
}
