<form class="form" [formGroup]="form" (ngSubmit)="login()">
  <!-- <mat-form-field class="form__input">
    <input matInput placeholder="{{'pages.login.input.email' | translate}}"  type="email" formControlName="email">
  </mat-form-field> -->

  <!-- <mat-form-field class="form-group position-relative align-div">
    <input matInput placeholder="{{'pages.login.input.password' | translate}}" type="password" formControlName="password">
  </mat-form-field> -->
  <div class="logindiv">
  <div class="form-group position-relative align-div">
    <input type="text" placeholder="{{'pages.login.input.email' | translate}}"  class="form-control"  type="email" formControlName="email">
  </div>
  <div class="form-group position-relative align-div">
    <input type="text" class="form-control" placeholder="{{'pages.login.input.password' | translate}}" type="password" formControlName="password">
  </div>
  <div class="form-actions">
    <button class="form-actions__forget ps-0" type="button" mat-button>{{'pages.login.buttons.forgot' | translate}}</button>
    <button class="form-actions__login px-5" mat-raised-button color="primary" type="submit">{{'pages.login.buttons.login' | translate}}</button>
  </div>
</div>
</form>
